body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*@import '~antd/dist/antd.css';*/


.breadcrumb {
    padding: 8px 15px;
    margin-bottom: 20px;
    list-style: none;
    background-color: #f6f6f6;
    border-radius: 2px;
    text-align: center;
}

.breadcrumb>li {
    display: inline-block;
}

.breadcrumb>.active {
    color: #333333;
    font-weight: bold;
}

.breadcrumb>li+li:before {
    content: "> ";
    padding: 0 5px;
    color: #767676;
}

.login-form, .signup-form {
    min-width: 400px;
    margin: 0 auto !important;
}
@media screen and (max-width: 576px) {
    .login-form, .signup-form {
        min-width: 320px;
    }
}
