/*@import '~antd/dist/antd.css';*/
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';


.breadcrumb {
    padding: 8px 15px;
    margin-bottom: 20px;
    list-style: none;
    background-color: #f6f6f6;
    border-radius: 2px;
    text-align: center;
}

.breadcrumb>li {
    display: inline-block;
}

.breadcrumb>.active {
    color: #333333;
    font-weight: bold;
}

.breadcrumb>li+li:before {
    content: "> ";
    padding: 0 5px;
    color: #767676;
}

.login-form, .signup-form {
    min-width: 400px;
    margin: 0 auto !important;
}
@media screen and (max-width: 576px) {
    .login-form, .signup-form {
        min-width: 320px;
    }
}